import React, { Component } from 'react';
import { Container } from '@material-ui/core';
import { FilterType, Table, TableFilter, Box } from 'portal-commons';
import { isEqual } from 'lodash';
import { Loader, FilterToggleButton } from '../../../shared_elements';
import {
  CampaignsByCspsListingRow,
  CampaignsActionDialog,
} from '../components';
import { globalGetService } from '../../../utils/globalApiServices';
import queryString from 'query-string';
import '../../../assets/styles/campaigns-by-csps-module.scss';
import { getAllCnps, getAllUsecaseTypes } from '../apis';
import { withFilters } from '../../../hocs';

const FILTER_CONFIG = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Type Campaign ID',
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
  },
  sharingStatus: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Accepted', value: 'ACCEPTED' },
      { label: 'Pending', value: 'PENDING' },
    ],
    label: 'Acceptance Status',
    multiple: true,
    width: 260,
  },
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Type Brand ID',
  },
  brandUniversalEin: {
    type: FilterType.Text,
    label: 'Brand Universal EIN',
    placeholder: 'Type Brand Universal EIN',
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Type CSP name',
    suggestions: [],
  },
  downstreamCnpUid: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Downstream CNP',
    multiple: true,
    width: 320,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Type Brand name',
    suggestions: [],
  },
  provisional: {
    type: FilterType.Dropdown,
    label: 'CNP Migration',
    placeholder: 'Select CNP Migration',
    options: [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ],
  },
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'cspName', label: 'CSP NAME', sortable: false },
  { id: 'downstreamCnpName', label: 'DOWNSTREAM CNP', sortable: false },
  { id: 'usecase', label: 'USECASE', sortable: true },
  { id: 'sharedOnDate', label: 'SHARED ON', sortable: true },
  { id: 'status', label: 'TCR STATUS', sortable: false },
  { id: 'sharingStatus', label: 'ACCEPTANCE STATUS', sortable: false },
];

const fetchCspSuggestions = (query = {}) =>
  globalGetService('dca/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d.cspName) ?? [];
      }
      return [];
    }
  );

const fetchBrandSuggestions = (query = {}) =>
  globalGetService('dca/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.brandName) ?? [];
      }
      return [];
    }
  );

class CampaignsByCsps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      campaignInfo: {},
      additionalFilters: {
        sortField: 'id',
        ascendingOrder: false,
      },
      tableLoader: false,
      actionFlag: {},
      campaignUid: '',
      brandName: '',
      cspUid: '',
      isFilterVisible: false,
    };
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const cnps = await getAllCnps();
    const usecaseTypes = await getAllUsecaseTypes();

    setOptions(
      'downstreamCnpUid',
      cnps.map((item) => ({
        label: item.displayName,
        value: item.uid,
      }))
    );
    setOptions(
      'usecase',
      usecaseTypes.map((item) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  processFilterSpecialCase = (filterValues = {}) => {
    const { configs, setConfig } = this.props.filter;
    const isStatusExpired = filterValues['status'] === 'EXPIRED';
    const newValues = { ...filterValues };
    if (isStatusExpired && filterValues['sharingStatus'] !== 'ACCEPTED') {
      newValues['sharingStatus'] = 'ACCEPTED';
    }
    if (Boolean(configs.sharingStatus.disabled) !== isStatusExpired) {
      setConfig('sharingStatus', {
        ...configs.sharingStatus,
        disabled: isStatusExpired,
      });
    }

    return newValues;
  };

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;

    const querys = queryString.parse(params, { decode: true });
    if (!Object.keys(querys).length) {
      updatedFilter = {
        sortField: 'createDate',
        ascendingOrder: false,
        // sharingStatus: 'PENDING,ACCEPTED'
      };
    } else if (querys.sortField && querys.ascendingOrder) {
      updatedFilter = {
        sortField: querys.sortField,
        ascendingOrder: JSON.parse(querys.ascendingOrder),
      };
    }
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: updatedFilter,
    }));

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: querys.page ? querys.page : 1,
    };
  };

  replaceUrlIfMissRequiredFilter = () => {
    const { search, pathname } = this.props.location;
    const queries = queryString.parse(search, { decode: true });

    if (!['true', 'false'].includes(queries.provisional)) {
      queries.provisional = false;
      this.props.history.replace({
        pathname: pathname,
        search: queryString.stringify(queries, { encode: true }),
      });
      return true;
    }

    return false;
  };

  componentDidMount() {
    const { additionalFilters } = this.state;
    this.fetchFilterOptions();
    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    if (this.props.location.search) {
      const queries = this.parseSearchParams(this.props.location.search);
      const finalQuery = this.processFilterSpecialCase(queries);
      if (isEqual(queries, finalQuery)) {
        this.fetchCampaignsAsPrimaryDca(finalQuery);
      } else {
        this.writeQueryString(finalQuery);
      }
    } else {
      this.fetchCampaignsAsPrimaryDca({ ...additionalFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.replaceUrlIfMissRequiredFilter()) {
      return;
    }
    // debugger
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsAsPrimaryDca(params);
    }
    if (
      !isEqual(
        prevProps.filter.candidateFilters,
        this.props.filter.candidateFilters
      )
    ) {
      const params = this.processFilterSpecialCase(
        this.props.filter.candidateFilters
      );
      this.props.filter.handleEdit(params);
    }
  }

  fetchCampaignsAsPrimaryDca = (query = {}) => {
    this.setState({
      tableLoader: true,
    });
    globalGetService(`dca/campaigns/primaryDca`, {
      sharingStatus: 'PENDING,ACCEPTED',
      ...query,
    }).then((response) => {
      this.setState({
        tableLoader: false,
      });
      if (response.status >= 200 && response.status < 300) {
        this.setState({
          campaignInfo: response.data,
          loader: false,
        });
        if (
          response.data.records.length == 0 &&
          response.data.totalRecords > 0 &&
          response.data.page > 1
        ) {
          const lastPageNo = Math.ceil(response.data.totalRecords / 10);
          this.setState({
            loader: true,
          });
          this.writeQueryString({ ...query, page: lastPageNo });
          // this.getCampaignsListApi({...query, page: lastPageNo})
        }
      }
    });
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { campaignInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));
    if (campaignInfo.totalRecords) {
      //    this.fetchCampaignsAsPrimaryDca({...filter, ...appliedFilterObj, page: campaignInfo.page, sortField, ascendingOrder: !filter.ascendingOrder });
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  handleActionClick = (type, campaignUid, campaignData = {}) => {
    if (type == 'accept') {
      this.setState((prevState) => ({
        actionFlag: { accept: true },
        campaignUid,
      }));
    } else {
      this.setState((prevState) => ({
        actionFlag: { reject: true },
        campaignUid: campaignUid,
        brandName: campaignData.brandName,
        cspUid: campaignData.cspUid,
      }));
    }
  };

  handleClose = (success = '') => {
    if (success) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsAsPrimaryDca(params);
    }
    this.setState((prevState) => ({
      actionFlag: {},
    }));
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: true })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  render() {
    const {
      loader,
      campaignInfo,
      additionalFilters,
      tableLoader,
      actionFlag,
      campaignUid,
      brandName,
      cspUid,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    return (
      <section
        className="campaigns-by-csps-section"
        data-testid="campaignByCsps"
      >
        <Container maxWidth={false} className="campaigns-by-csps-container">
          {loader ? (
            <Loader />
          ) : (
            <>
              <Box className="page-actions">
                <FilterToggleButton
                  visible={isFilterVisible}
                  onClick={this.toggleFilterVisibility}
                />
              </Box>
              {isFilterVisible && (
                <TableFilter
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={handleCandidateFiltersChange}
                  onAppliedValuesChange={handleAppliedFiltersChange}
                  className="filters"
                  dataAttributes={{
                    'data-testid': 'campaignByCspsTableFilter',
                  }}
                />
              )}
              <Table
                testId="campaignByCspsTable"
                records={{ total: campaignInfo.totalRecords }}
                loading={tableLoader}
                handleChangePage={this.handleChangePage}
                createSortHandler={this.handleSorting}
                emptyState="no campaigns to view"
                filter={additionalFilters}
                headRows={headRows}
                tableData={campaignInfo.records.map((record, index) => (
                  <CampaignsByCspsListingRow
                    handleActionClick={this.handleActionClick}
                    key={index}
                    campaign={record}
                    role={this.props.role}
                  />
                ))}
                pagination={{
                  page: campaignInfo.page,
                  rowsPerPage: campaignInfo.recordsPerPage,
                  totalRecords: campaignInfo.totalRecords,
                }}
              />
            </>
          )}
        </Container>
        {actionFlag.accept || actionFlag.reject ? (
          <CampaignsActionDialog
            open={true}
            handleClose={this.handleClose}
            type={actionFlag.accept ? 'accept' : 'reject'}
            campaignUid={campaignUid}
            brandName={brandName}
            cspUid={cspUid}
          />
        ) : null}
      </section>
    );
  }
}

export default withFilters(CampaignsByCsps, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value) => fetchBrandSuggestions({ prefix: value }),
      },
      cspName: {
        minLength: 2,
        load: (value) => fetchCspSuggestions({ prefix: value }),
      },
    },
  },
});
