import React, { useMemo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { fetchUsers, fetchUserRoles } from '../../apiServices';

import { Table, Flex, BoxV2 as Box, Button } from 'portal-commons';
import { Roles } from '../../../../constants/enums';
import { UserData, SelectOption, UserProfile } from '../../types';
import MfaBlk from '../MfaBlk';
import UsersListingRow from '../UsersListingRow';
import UserModal from '../UserModal';

const useStyles = makeStyles(() => ({
  userTable: {
    minHeight: 44,
    ' .empty-row': {
      height: 160,
    },
  },
}));

const headRows = [
  { id: 'fullName', label: 'NAME', sortable: true },
  { id: 'email', label: 'E-MAIL', sortable: false },
  { id: 'dcaUid', label: 'USER ID', sortable: false },
  { id: 'dcaUserRole', label: 'ROLE', sortable: true },
  { id: 'created', label: 'CREATED ON', sortable: true },
  { id: 'lastLogin', label: 'LAST LOGIN', sortable: true },
  { id: 'action', label: '', sortable: false },
];

interface UserDataExtended extends UserData {
  fullName: string;
  email: string;
  dcaUid: string;
  dcaUserRole: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const UserManagement: React.FC<{
  editable: boolean;
  profile?: UserProfile;
}> = ({ editable, profile }) => {
  const classes = useStyles();
  const [maxUsers, setMaxUsers] = useState(profile?.maxPortalUsers ?? 10);
  const [loading, setLoading] = useState(false);
  const [modalMode, setModalMode] = useState<'new' | 'edit' | 'delete'>();
  const [users, setUsers] = useState<UserDataExtended[]>([]);
  const pageSize = 10;
  const [filter, setFilter] = useState({
    sortField: '',
    ascendingOrder: false,
  });
  const [currPage, setCurrPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState<UserData>();
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (profile) {
      setMaxUsers(profile.maxPortalUsers);
    }
  }, [profile]);

  const getUsers = async (delay = false) => {
    setLoading(true);
    // TCR-12401: Workaround for Okta update user delay
    delay && (await new Promise((resolve) => setTimeout(resolve, 2000)));

    const result = await fetchUsers();
    const data = result.map((user: UserData) => {
      return {
        ...user,
        fullName: user.profile.fullName,
        email: user.profile.email,
        dcaUid: user.profile.dcaUid,
        dcaUserRole: user.profile.dcaUserRole
          ? user.profile.dcaUserRole[0]
          : Roles.Reviewer,
      };
    });
    setUsers(data);
    setLoading(false);
  };

  const handleCloseModal = (fetchUser = false, delay = false) => {
    setSelectedUser(undefined);
    setModalMode(undefined);
    fetchUser && getUsers(delay);
  };

  const handleSortField = (field: string) => {
    setFilter((prev) => ({
      ...prev,
      sortField: field,
      ascendingOrder: prev.sortField === field ? !prev.ascendingOrder : true,
    }));
  };

  useEffect(() => {
    getUsers();

    fetchUserRoles().then((data) => {
      const options: SelectOption[] = data.map((userRole: string) => {
        const roleLabel = userRole.split('_')[1].toLowerCase();
        return {
          label: roleLabel.charAt(0).toUpperCase() + roleLabel.slice(1),
          value: userRole,
        };
      });
      setRoleOptions(options);
    });
  }, []);

  const displayUsers = useMemo(() => {
    if (users.length === 0) return [];

    let tempData = users.slice();
    if (filter.sortField) {
      tempData = tempData.sort((a, b) => {
        const { sortField, ascendingOrder } = filter;
        const dataA = a[sortField];
        const dataB = b[sortField];
        if (typeof dataA === 'string' && typeof dataB === 'string') {
          return ascendingOrder
            ? (dataA as string).localeCompare(dataB)
            : (dataB as string).localeCompare(dataA);
        } else if (typeof dataA === 'number' && typeof dataB === 'number') {
          return ascendingOrder ? dataA - dataB : dataB - dataA;
        } else {
          return 0;
        }
      });
    }
    if (tempData.length === users.length && tempData.length > pageSize) {
      const start = (currPage - 1) * pageSize;
      const end = start + pageSize;
      tempData = tempData.slice(start, end);
    }
    return tempData;
  }, [users, filter, pageSize, currPage]);

  return (
    <Box
      sx={{
        mt: 'xxl',
        p: 'xxl',
        border: '1px solid #C0CCD4',
        fontFamily: 'normal',
        color: 't.grey500',
        lineHeight: 'normal',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'space-between',
          fontSize: 'H300',
          lineHeight: 'normal',
          width: '100%',
          pb: 'm',
          borderBottom: '1px solid',
          borderColor: '#CCCFD0',
        }}
      >
        <Flex
          sx={{
            alignItems: 'center',
            fontSize: 'H500',
            fontWeight: 600,
          }}
        >
          <FontAwesomeIcon
            icon={faCircleUser}
            style={{ marginRight: '8px' }}
            fontSize={24}
          />
          <Box sx={{ fontSize: 'H500' }} as="h2">
            User Management
          </Box>
        </Flex>
        {editable && (
          <Button
            shape="square"
            size="small"
            disabled={users.length >= maxUsers}
            onClick={() => {
              setModalMode('new');
            }}
            data-testid="createNewUserButton"
          >
            Create New User
          </Button>
        )}
      </Flex>
      <Box sx={{ my: 'l' }}>
        <MfaBlk editable={editable} profile={profile} />
      </Box>

      <Table
        className={classes.userTable}
        testId="usersTable"
        loading={loading}
        headRows={headRows}
        disableHover
        emptyState="No users"
        records={{ total: users.length }}
        data={displayUsers}
        filter={filter}
        renderRow={(data) => {
          return (
            <UsersListingRow
              user={data as unknown as UserData}
              key={data.id as string}
              editable={editable}
              onEditUser={(user) => {
                setModalMode('edit');
                setSelectedUser(user);
              }}
              onDeleteUser={(user) => {
                setModalMode('delete');
                setSelectedUser(user);
              }}
            />
          );
        }}
        handleChangePage={(page) => setCurrPage(page)}
        createSortHandler={handleSortField}
        pagination={{
          page: currPage,
          rowsPerPage: pageSize,
          totalRecords: users.length,
        }}
      />
      {users.length >= maxUsers && (
        <Box sx={{ mt: 'l', textAlign: 'center' }}>
          Maximum number of users reached!
          <br />
          Please delete existing users if you wish to create a new one.
        </Box>
      )}

      {modalMode && (
        <UserModal
          open={!!modalMode}
          mode={modalMode}
          user={selectedUser}
          roleOptions={roleOptions}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default UserManagement;
